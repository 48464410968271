@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '../../assets/styles/global/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/styles/global/mixins';

.section--services {
  padding-top: 0.5rem;
  padding-bottom: 0;

  @include media-breakpoint-down(lg) {
    padding-bottom: calc(#{map-get($spacers, 5)} - 12px);

    + .section--testimonials.section-paddings {
      padding-top: 0;
    }
  }
}

.services {
  &__top {
    position: relative;
    padding-top: 11%;
    padding-bottom: 11%;
    padding-left: 9%;

    @include media-breakpoint-up(xxl) {
      padding-top: 9.75rem;
      padding-bottom: 9.75rem;
      padding-left: 8.125rem;
    }

    @include media-breakpoint-down(xxl) {
      max-width: 1380px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    > .row {
      position: relative;
      z-index: 2;
    }
  }

  &__top-image {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: calc(50% + 40px);
    width: auto;
    height: auto;
    max-height: 100%;
    transform: translate(-50%, -50%);

    @include media-breakpoint-down(xl) {
      left: 40%;
    }

    @include media-breakpoint-down(md) {
      left: 50%;
    }
  }

  &__title {
    position: relative;
    line-height: 1.1;
    text-align: left;

    @media (min-width: 1280px) {
      font-size: 5.25rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 32px;
      line-height: 36px;
    }

    &::before {
      content: '';
      display: inline-block;
      width: 10%;

      @include media-breakpoint-down(lg) {
        width: 8%;
      }

      @include media-breakpoint-down(sm) {
        content: none;
      }
    }

    .star-icon {
      position: absolute;
      bottom: calc(100% + 1.25rem);
      left: 0;
      transform-origin: center;
      animation: star-blinking-rotate 4s infinite ease-in-out;

      @include media-breakpoint-down(lg) {
        bottom: 100%;
      }

      @include media-breakpoint-down(sm) {
        left: 78%;
        bottom: calc(100% + 30px);
      }

      svg {
        width: 5rem;
        height: 5rem;
        object-fit: contain;

        @include media-breakpoint-down(lg) {
          width: 45px;
          height: 45px;
        }
      }
    }
  }

  &__content {
    max-width: 20.3rem;
    margin-right: 5%;
    margin-left: auto;

    @include media-breakpoint-down(md) {
      margin: 36px auto 0;
      text-align: center;

      &--type2 {
        margin-top: 0;
        margin-bottom: 36px;
      }
    }

    @include media-breakpoint-down(sm) {
      max-width: 340px;
      text-align: left;
      margin-left: 0;
    }

    .btn {
      margin-top: 1.5rem;

      @include media-breakpoint-down(md) {
        margin-top: 14px;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 14.375rem;
    padding: 1.5rem;
    border: 1px solid $black;
    //border-radius: 16px;
    text-decoration: none;
    color: inherit;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out,
      opacity 0.6s ease-in-out, transform 0.6s ease-in-out;

    @include media-breakpoint-down(lg) {
      padding: 18px;
      background-color: $primary;
      border-color: $black;
    }

    @include media-breakpoint-down(md) {
      opacity: 1 !important;
      transform: none !important;
    }

    &:hover {
      color: inherit;
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        background-color: $primary;
        border-color: $black;

        .service__data {
          opacity: 1;
          visibility: visible;
          transition: all 0.6s ease-in-out;
        }

        .service__centered {
          opacity: 0;
          transition: none;
        }
      }
    }

    .service__centered {
      @include media-breakpoint-up(lg) {
        position: absolute;
        inset: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 1.5rem;
        text-align: center;
      }

      @include media-breakpoint-down(lg) {
        color: $white;
      }
    }

    .service__icon {
      &-wrapper {
        max-width: 56px;
        max-height: 56px;
        display: block;
        margin: -2.5rem auto 1.875rem;

        @include theme-mode(dark) {
          &[data-bs-theme='light'] {
            display: none;
          }
        }

        @include theme-mode(light) {
          &[data-bs-theme='dark'] {
            display: none;
          }
        }

        @include media-breakpoint-down(lg) {
          margin: 0 0 1rem;

          &[data-bs-theme='dark'] {
            display: block !important;
          }

          &[data-bs-theme='light'] {
            display: none !important;
          }
        }
      }
    }

    .service__data {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex: 1 1 100%;
      opacity: 0;
      visibility: hidden;
      transition: none;
      color: $black;

      @include media-breakpoint-down(lg) {
        opacity: 1;
        visibility: visible;
      }
    }

    .service__title {
      font-weight: 600;
      //width: calc(100% - 3rem);
      transition: all 0.5s ease-in-out;

      @include media-breakpoint-down(lg) {
        color: $white;
      }
    }

    .btn {
      &:hover {
        color: $black;
        background-color: $white;
        opacity: 0.5;
      }
    }
  }
}

.services-slider {
  margin-top: 1.5rem;

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }

  .splide__slide {
    display: flex;
  }

  .splide:not(.is-active) {
    padding-right: 10px;
    padding-left: 10px;

    .splide__track {
      @include make-cssgrid();
    }

    .splide__list {
      display: flex;
      justify-content: center;
      margin-right: -5px !important;
      margin-left: -5px !important;

      @include media-breakpoint-up(lg) {
        flex-wrap: wrap;
      }
    }

    .splide__slide {
      flex: 0 1 25%;
      max-width: 25%;
      padding-right: 5px;
      padding-left: 5px;

      @include media-breakpoint-up(lg) {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      @include media-breakpoint-down(lg) {
        flex: 0 1 15rem;
        max-width: 15rem;
      }
    }

    .services__item {
      width: 100%;
    }
  }
}
